import { IMenu, IMenuResponse } from "./types";

export const mapMenuValues = (response: IMenuResponse): IMenu => ({
  id: response.data.id,
  name: response.data.name,
  notes: response.data.notes ?? "",
  summary: response.data.summary ?? "",
  isDefault: response.data.is_default,
  hideSnoozedItems: response.data.hide_snoozed_items,
});
