import { http } from "../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";

type IFetchOutletsActiveIntegrationContract = {
  outlet_id: string;
  source: {
    id: number;
    name: string;
    is_editable: boolean;
    is_integration: boolean;
  };
};

export type IFetchOutletsActiveIntegrationResponse = {
  outletId: string;
  source: {
    id: number;
    name: string;
    isEditable: boolean;
    isIntegration: boolean;
  };
};

export class FetchOutletsActiveIntegrationError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchOutletsActiveIntegration");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchOutletsActiveIntegration(
  businessId: string
): Promise<Array<IFetchOutletsActiveIntegrationResponse>> {
  let response: AxiosResponse;

  try {
    response = await http.get(`/api/v4/businesses/${businessId}/integrations`);
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchOutletsActiveIntegrationError(error);
  }

  return response.data?.data.length
    ? response.data.data.map((obj: IFetchOutletsActiveIntegrationContract) => {
        return {
          outletId: obj.outlet_id,
          source: {
            id: obj.source.id,
            name: obj.source.name,
            isEditable: obj.source.is_editable,
            isIntegration: obj.source.is_integration,
          },
        };
      })
    : [];
}
