


























































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  computed,
  onBeforeUnmount,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomModal,
  MolSearchBox,
  AtomLoading,
  AtomButton,
  AtomButtonTypeEnum,
  OrgCollapsableSelectList,
} from "@/v2/new-design-system";
import {
  fetchOutletsFromBusiness,
  IFetchOutletsFromBusiness,
} from "@/v2/repo/fetch-outlets-from-business";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";
import { syncOutletConnectionsForMenu } from "@/v2/repo/menu/sync-outlet-connections-for-menu";
import { Toast } from "@/design-system";
import { OrderingServiceOutletEnum } from "@/v2/enum";
import { fetchMenuIntegration } from "@/v2/repo/fetch-menu-integration";
import { fetchDeliverectChannelLinks } from "@/v2/repo/fetch-deliverect-channel-links";
import {
  fetchOutletsActiveIntegration,
  IFetchOutletsActiveIntegrationResponse,
} from "@/v2/repo/fetch-outlets-active-integration";
import { MenuManagementSourceByIdEnum } from "@/v2/enum";
import { unWatchFlag, watchFlag } from "@/v2/core/feature-flag";

const css = bemBuilder("connection-menu-modal");

interface IOutletData {
  name: string;
  items: Array<{
    id: string;
    name: string;
    selected: boolean;
    isEnabled: boolean;
    disabledText?: string;
    isDisabled?: boolean;
  }>;
}

export default defineComponent({
  name: "ConnectionMenuModal",
  components: {
    AtomText,
    AtomModal,
    MolSearchBox,
    AtomLoading,
    AtomButton,
    OrgCollapsableSelectList,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    menuId: {
      type: String,
      required: true,
    },
    menuName: {
      type: String,
      required: true,
    },
    selectedServices: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    sourceId: {
      type: Number as PropType<MenuManagementSourceByIdEnum>,
      required: true,
    },
    sourceIsEditable: {
      type: Boolean,
      default: false,
    },
    sourceIsIntegration: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onSuccess: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
  },
  setup(props) {
    const searchOutletFilter = ref("");
    const outletsData = ref<Array<IOutletData>>([]);
    const outletsWithIntegration = ref<Array<string>>([]);
    const outletsActiveIntegration = ref<
      Array<IFetchOutletsActiveIntegrationResponse>
    >([]);

    const isLoading = ref(true);

    // Feature flag for the omnivore integration
    const omnivoreIntegration = ref(false);
    function updateOmnivoreFlag(val: boolean) {
      omnivoreIntegration.value = val;
    }

    onMounted(async () => {
      isLoading.value = true;

      watchFlag("omnivore", updateOmnivoreFlag);

      try {
        let [integrations, deliverect] = await Promise.all([
          fetchMenuIntegration(),
          fetchDeliverectChannelLinks(),
        ]);

        const concatenateIntegrations = [...integrations, ...deliverect];
        outletsWithIntegration.value = concatenateIntegrations.map(
          (obj) => obj.outletId
        );

        if (omnivoreIntegration.value) {
          outletsActiveIntegration.value = await fetchOutletsActiveIntegration(
            props.businessId
          );
        }
      } catch (e: any) {
        generalErrorToast();
        report(e);
      }

      await fetchOutlets();

      isLoading.value = false;
    });

    onBeforeUnmount(() => {
      unWatchFlag("omnivore", updateOmnivoreFlag);
    });

    async function fetchOutlets() {
      let response: Array<IFetchOutletsFromBusiness> | undefined;

      try {
        response = await fetchOutletsFromBusiness(props.businessId);
      } catch (e: any) {
        props.onClose();
        generalErrorToast();
        report(e);
      }

      if (!response) return;

      outletsData.value = response.map((outlet) => {
        return {
          name: outlet.name,
          items: [
            {
              id: `${outlet.id}:${OrderingServiceOutletEnum.DELIVERY}`,
              name: t("module.menu.connection_menu_modal.delivery"),
              selected: false,
              isEnabled: outlet.orderingServiceState["delivery"] === "ongoing",
              isDisabled: disableService(
                outlet.orderingServiceState["delivery"],
                outlet.id
              ),
            },
            {
              id: `${outlet.id}:${OrderingServiceOutletEnum.PICKUP}`,
              name: t("module.menu.connection_menu_modal.pickup"),
              selected: false,
              isEnabled: outlet.orderingServiceState["pickup"] === "ongoing",
              isDisabled: disableService(
                outlet.orderingServiceState["pickup"],
                outlet.id
              ),
            },
            {
              id: `${outlet.id}:${OrderingServiceOutletEnum.DINE_IN}`,
              name: t("module.menu.connection_menu_modal.dinein"),
              selected: false,
              isEnabled: outlet.orderingServiceState["dinein"] === "ongoing",
              isDisabled: disableService(
                outlet.orderingServiceState["dinein"],
                outlet.id
              ),
            },
          ],
        };
      });
    }

    function disableService(service: string, outletId: string): boolean {
      if (
        service === "disabled" ||
        outletsWithIntegration.value.includes(outletId)
      ) {
        return true;
      }

      if (omnivoreIntegration.value) {
        const outletIntegrated = outletsActiveIntegration.value.find(
          (obj) => obj.outletId === outletId
        );

        const outletIntegratedNotMatchSourceId = Boolean(
          outletIntegrated && outletIntegrated.source.id !== props.sourceId
        );

        const outletNotIntegratedSourceIntegrated = Boolean(
          !outletIntegrated && props.sourceIsIntegration
        );

        if (
          outletIntegratedNotMatchSourceId ||
          outletNotIntegratedSourceIntegrated
        ) {
          return true;
        }
      }

      return false;
    }

    const matchSelectedServices = computed(() => {
      let servicesEnabled = outletsData.value.reduce((result, outlet) => {
        const partialResult = outlet.items.reduce((output, item) => {
          if (item.isEnabled) return output.concat(item.id);
          return output;
        }, [] as string[]);
        return result.concat(partialResult);
      }, [] as string[]);

      return servicesEnabled.filter((services) =>
        props.selectedServices.includes(services)
      );
    });

    const outletsToBeUpdated = ref(Array<string>());
    const isSaving = ref(false);

    const updateMenuForOutlets = async () => {
      let response = ref(false);

      try {
        isSaving.value = true;
        response.value = await syncOutletConnectionsForMenu(
          { businessId: props.businessId, menuId: props.menuId },
          outletsToBeUpdated.value
        );
      } catch (e: any) {
        new Toast().create({
          type: "error",
          title: t("module.menu.connection_menu_modal.error_title_message"),
          text: t(
            "module.menu.connection_menu_modal.error_description_message",
            { menuName: props.menuName }
          ),
        });
        report(e);
      } finally {
        isSaving.value = false;
        props.onClose();
      }

      if (!response.value) return;

      props.onSuccess();
      new Toast().create({
        type: "success",
        title: t("module.menu.connection_menu_modal.success_title_message"),
        text: t(
          "module.menu.connection_menu_modal.success_description_message",
          {
            menuName: props.menuName,
          }
        ),
      });
    };

    const onChangeMenuForOutlets = (selectedItems: Array<string>) => {
      outletsToBeUpdated.value = selectedItems;
    };

    const isButtonDisabled = computed(() => {
      if (outletsToBeUpdated.value.length === 0) return true;

      if (outletsToBeUpdated.value.length != props.selectedServices.length)
        return false;

      var updatedServices = new Set(outletsToBeUpdated.value);
      var initialServices = new Set(props.selectedServices);

      return [...initialServices].every((value) => updatedServices.has(value));
    });

    return {
      t,
      css,
      matchSelectedServices,
      onChangeMenuForOutlets,
      updateMenuForOutlets,
      isLoading,
      isSaving,
      isButtonDisabled,
      searchOutletFilter,
      outletsData,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      outletsActiveIntegration,
      outletsWithIntegration,
    };
  },
});
