import { http } from "../../http";
import { IRepoErrors } from "../../errors";
import { OrderingServiceOutletEnum } from "@/v2/enum/ordering-service.enum";

interface IChangeMenuRequest {
  businessId: string;
  menuId: string;
}

interface IAttachMenuBody {
  outlet_id: string;
  ordering_services: Array<OrderingServiceOutletEnum>;
}

export class ChangeMenuError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: ChangeMenu");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

const getFormattedPayload = (
  payload: Array<string>
): Array<IAttachMenuBody> => {
  const formattedData = Array<IAttachMenuBody>();
  payload.forEach(function (outlet) {
    const outletId = outlet.split(":")[0];
    const outletFound = formattedData.find((el) => el.outlet_id === outletId);
    const outletOrderingService = outlet.split(
      ":"
    )[1] as OrderingServiceOutletEnum;

    outletFound
      ? outletFound.ordering_services.push(outletOrderingService)
      : formattedData.push({
          outlet_id: outletId,
          ordering_services: [outletOrderingService],
        });
  });

  return formattedData;
};

export async function syncOutletConnectionsForMenu(
  request: IChangeMenuRequest,
  payload: Array<string>
): Promise<boolean> {
  let response: boolean;

  try {
    response = await http.post(
      `/api/v3/businesses/${request.businessId}/menus/${request.menuId}/change-menu-for-outlets`,
      getFormattedPayload(payload)
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new ChangeMenuError(error);
  }

  return Boolean(response);
}
