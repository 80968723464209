import { AxiosResponse } from "axios";
import { IRepoErrors } from "../errors";
import { http } from "../http";
import { IFetchMenusFromBusinessApiResponse } from "./api-response.factory";
import { OrderingServiceOutletEnum } from "@/v2/enum/ordering-service.enum";

export class fetchMenusFromBusinessError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: fetchMenusFromBusiness");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export interface IFetchMenusFromBusinessResponse {
  data: Array<{
    id: string;
    name: string;
    notes: string | null;
    state: "draft" | "live";
    isDefault: boolean;
    updatedAt: Date;
    attachedOutlets: Array<{
      outletId: string;
      orderingServices: Array<OrderingServiceOutletEnum>;
    }>;
    source: {
      id: number;
      name: string;
      isEditable: boolean;
      isIntegration: boolean;
    };
  }>;
  pagination: {
    currentPage: number;
    lastPage: number;
    perPage: number;
    total: number;
  };
}

export async function fetchMenusFromBusiness(
  businessId: string,
  page: number,
  searchNameQuery?: string,
  sortDir?: "DESC" | "ASC"
): Promise<IFetchMenusFromBusinessResponse> {
  let response: AxiosResponse<IFetchMenusFromBusinessApiResponse>;
  try {
    response = await http.get(
      `/api/v4/businesses/${businessId}/menus?include=attached_outlets`,
      {
        params: {
          limit: 15,
          sort: `${!sortDir || sortDir === "DESC" ? "-" : ""}updated_at`,
          page: page,
          name: searchNameQuery ?? "",
        },
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new fetchMenusFromBusinessError(error);
  }

  return {
    data: response.data.data.map((menu) => ({
      id: menu.id,
      isDefault: menu.is_default,
      name: menu.name,
      notes: menu.notes ?? "",
      state: menu.state,
      updatedAt: new Date(menu.updated_at),
      attachedOutlets: menu.attached_outlets.map((attachedOutlet) => ({
        outletId: attachedOutlet.outlet_id,
        orderingServices: attachedOutlet.ordering_services,
      })),
      source: {
        id: menu.source.id,
        name: menu.source.name,
        isEditable: menu.source.is_editable,
        isIntegration: menu.source.is_integration,
      },
    })),
    pagination: {
      currentPage: response.data.meta.current_page,
      lastPage: response.data.meta.last_page,
      perPage: response.data.meta.per_page,
      total: response.data.meta.total,
    },
  };
}
